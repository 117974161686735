'use strict';

angular.module('risevision.displays.services')
  .factory('displayFactory', ['$rootScope', '$q', '$state', '$log',
    'userState', 'display', 'trackerFactory', 'schedule', 'scheduleFactory', 'playerLicenseFactory',
    'featuresService', 'processErrorCode',
    function ($rootScope, $q, $state, $log, userState, display, trackerFactory,
      schedule, scheduleFactory, playerLicenseFactory, featuresService, processErrorCode) {
      var factory = {};

      var playerNamesWithHiddenProductNames = [
        'Android Player'
      ];

      var _clearMessages = function () {
        factory.loadingDisplay = false;
        factory.savingDisplay = false;

        factory.apiError = '';
      };

      factory.init = function () {
        factory.display = {
          'width': 1920,
          'height': 1080,
          'status': 1,
          'restartEnabled': true,
          'restartTime': '02:00',
          'autostartOnBoot': true,
          'monitoringEnabled': false,
          'useCompanyAddress': true,
          'playerProAssigned': false,
          'playerProAuthorized': false
        };

        _clearMessages();
      };

      factory.init();

      factory.newDisplay = function () {
        trackerFactory.displayEvent('Add Display');

        factory.init();

        if (featuresService.isFeatureAvailable('monitoring')) {
          factory.display.monitoringEnabled = true;
        }

        if (playerLicenseFactory.isProAvailable(factory.display)) {
          factory.display.playerProAssigned = true;
          factory.display.playerProAuthorized = true;
        }
      };

      factory.getDisplay = function (displayId) {
        var deferred = $q.defer();

        _clearMessages();

        //show loading spinner
        factory.loadingDisplay = true;

        display.get(displayId)
          .then(function (result) {
            factory.display = result.item;
            factory.display.originalPlayerProAuthorized = factory.display.playerProAuthorized;

            factory.display.overrideSchedules = [];
            if (factory.hasOverrideSchedule(factory.display)) {

              for (var i = 0; i < factory.display.overrideScheduleIds.length; i++) {
                factory.display.overrideSchedules.push({
                  id: factory.display.overrideScheduleIds[i],
                  name: factory.display.overrideScheduleNames[i],
                  scheduleTypeName: ''
                });

                schedule.get(factory.display.overrideScheduleIds[i]).then(function (result) {
                  if (result.item) {
                    this.scheduleTypeName = result.item.scheduleTypeName;
                    this.companyId = result.item.companyId;
                  }
                }.bind(factory.display.overrideSchedules[i]));
              }
            }

            deferred.resolve();
          })
          .then(null, function (e) {
            _showErrorMessage('get', e);

            deferred.reject();
          })
          .finally(function () {
            factory.loadingDisplay = false;
          });

        return deferred.promise;
      };

      factory.addDisplay = function (selectedSchedule) {
        var deferred = $q.defer();

        _clearMessages();

        //show loading spinner
        factory.loadingDisplay = true;
        factory.savingDisplay = true;

        display.add(factory.display)
          .then(function (resp) {
            if (resp && resp.item && resp.item.id) {
              if (factory.display.playerProAuthorized) {
                playerLicenseFactory.toggleDisplayLicenseLocal(true);
              }

              if (factory.display.activationKey) {
                trackerFactory.displayEvent('Display Activated', resp.item.id, resp.item.name);
              } else {
                trackerFactory.displayEvent('Display Created', resp.item.id, resp.item.name);
              }

              return scheduleFactory.addToDistribution(resp.item, selectedSchedule)
                .then(function () {
                  if ($state.current.name === 'apps.displays.add' || $state.current.name === 'apps.displays.activate') {
                    $state.go('apps.displays.details', {
                      displayId: resp.item.id,
                      activated: !!factory.display.activationKey
                    });
                  }

                  deferred.resolve();
                })
                .catch(function () {
                  factory.apiError = scheduleFactory.apiError;
                  deferred.reject();
                });
            } else {
              return $q.reject();
            }
          }, function (e) {
            _showErrorMessage('add', e);
            deferred.reject();
          })
          .finally(function () {
            factory.loadingDisplay = false;
            factory.savingDisplay = false;
          });

        return deferred.promise;
      };

      factory.updateDisplay = function (selectedSchedule) {
        var deferred = $q.defer();

        _clearMessages();

        //show loading spinner
        factory.loadingDisplay = true;
        factory.savingDisplay = true;

        _update(factory.display.id, factory.display)
          .then(_updateLicenseIfNeeded)
          .then(function () {
            return scheduleFactory.addToDistribution(factory.display, selectedSchedule)
              .then(function () {
                deferred.resolve();
              })
              .catch(function () {
                factory.apiError = scheduleFactory.apiError;
                deferred.reject();
              });
          })
          .catch(function (e) {
            _showErrorMessage('update', e);
            deferred.reject();
          })
          .finally(function () {
            factory.loadingDisplay = false;
            factory.savingDisplay = false;
          });

        return deferred.promise;
      };

      var _update = function (displayId, fields) {
        return display.update(displayId, fields).then(function (result) {
          trackerFactory.displayEvent('Display Updated', displayId, fields && fields.name);
          return result;
        });
      };

      var _updateLicenseIfNeeded = function () {
        if (factory.display.playerProAuthorized !== factory.display.originalPlayerProAuthorized) {
          return playerLicenseFactory.updateDisplayLicense(factory.display)
            .then(function () {
              factory.display.originalPlayerProAuthorized = factory.display.playerProAuthorized;
            })
            .catch(function (err) {
              factory.apiError = playerLicenseFactory.apiError;
              return $q.reject(err);
            });
        } else {
          return $q.resolve();
        }
      };

      factory.applyFields = function (display, fields) {
        return _update(display.id, fields).then(function (result) {
          angular.extend(display, fields);
          return result;
        });
      };

      factory.deleteDisplayByObject = function (displayObject) {
        return display.delete(displayObject.id)
          .then(function () {
            trackerFactory.displayEvent('Display Deleted', displayObject.id, displayObject.name);

            if (displayObject.playerProAssigned) {
              playerLicenseFactory.toggleDisplayLicenseLocal(false);
            }
          });
      };

      factory.deleteDisplay = function () {
        _clearMessages();

        //show loading spinner
        factory.loadingDisplay = true;

        factory.deleteDisplayByObject(factory.display)
          .then(function () {
            factory.display = {};

            $state.go('apps.displays.list');
          })
          .then(null, function (e) {
            _showErrorMessage('delete', e);
          })
          .finally(function () {
            factory.loadingDisplay = false;
          });
      };

      var _showErrorMessage = function (action, e) {
        factory.errorMessage = 'Failed to ' + action + ' display.';
        factory.apiError = processErrorCode('Display', action, e);

        $log.error(factory.apiError, e);
      };

      factory.showLicenseRequired = function (display) {
        display = display || factory.display;

        return display && !display.playerProAuthorized && !userState.isRiseAdmin();
      };

      factory.showProductName = function () {
        const display = factory.display;

        return display && display.productName &&
          !playerNamesWithHiddenProductNames.includes(display.playerName);
      };

      factory.hasSchedule = function (display) {
        return display && display.scheduleId && display.scheduleId !== 'DEMO';
      };

      factory.hasOverrideSchedule = function (display) {
        return display && display.overrideScheduleIds && display.overrideScheduleIds.length > 0;
      };

      return factory;
    }
  ]);
