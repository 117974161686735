'use strict';

angular.module('risevision.storage.services')
  .factory('uploadOverwriteWarning', ['$q', 'ngModalService',
    function ($q, ngModalService) {
      var factory = {};

      factory.checkOverwrite = function (resp) {
        if (resp.isOverwrite === true) {
          //multiple uploads can trigger the modal, they should all use the same instance
          if (!factory.confirmOverwriteModal) {
            factory.confirmOverwriteModal = ngModalService.confirmDanger(
              'Are you sure you want to overwrite your files?',
              'There is a file or folder in this folder with the same name as the one(s) you are trying to upload.<br/>Uploading these new files or folder will cause the existing ones to be overwritten and could change the content on your Displays.',
              'Yes, overwrite files',
              'No, keep source files'
            );
          }
          return factory.confirmOverwriteModal;
        } else {
          return $q.resolve();
        }
      };

      factory.resetConfirmation = function () {
        factory.confirmOverwriteModal = undefined;
      };

      return factory;
    }
  ]);
