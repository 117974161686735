'use strict';

angular.module('risevision.schedules.directives')
  .directive('playlist', ['$modal', 'ngModalService', 'playlistFactory', 'timelineDescription',
    function ($modal, ngModalService, playlistFactory, timelineDescription) {
      return {
        restrict: 'E',
        scope: {
          playlistItems: '='
        },
        templateUrl: 'partials/schedules/playlist.html',
        link: function ($scope) {
          $scope.factory = playlistFactory;
          $scope.timelineDescription = timelineDescription;

          $scope.manage = function (playlistItem) {
            $modal.open({
              templateUrl: 'partials/schedules/playlist-item.html',
              controller: 'playlistItemModal',
              size: 'md',
              resolve: {
                playlistItem: function () {
                  return playlistItem;
                }
              }
            });
          };

          $scope.remove = function (playlistItem) {
            ngModalService.confirm('Remove Playlist Item?',
              'Are you sure you want to remove this item from the Playlist?',
              'Yes', 'No'
            ).then(function () {
              playlistFactory.removePlaylistItem(playlistItem);
            });
          };

          $scope.sortItem = function (evt) {
            var oldIndex = evt.data.oldIndex;
            var newIndex = evt.data.newIndex;

            playlistFactory.moveItem(oldIndex, newIndex);
          };
        } //link()
      };
    }
  ]);
