(function (angular) {
  'use strict';

  angular.module('risevision.core.userprofile', [
      'risevision.common.gapi'
    ])

    .constant('USER_WRITABLE_FIELDS', [
      'mailSyncEnabled', 'email', 'firstName', 'lastName', 'telephone',
      'roles', 'status', 'companyRole', 'dataCollectionDate', 'settings'
    ])

    .constant('BASIC_USER_WRITABLE_FIELDS', [
      'mailSyncEnabled', 'email', 'firstName', 'lastName', 'telephone',
      'status', 'companyRole', 'dataCollectionDate', 'settings'
    ])

    .factory('getUserProfile', ['coreAPILoader', '$q', '$log',
      function (coreAPILoader, $q, $log) {
        var _username;
        var _cachedPromises = {};

        return function (username, clearCache) {

          var deferred;

          if (username === _username && !clearCache &&
            _cachedPromises[username] !== null) {
            //avoid calling API if username didn't change
            return _cachedPromises[username].promise;
          } else {
            _username = username;
            _cachedPromises[username] = deferred = $q.defer();
          }

          if (!username) {
            deferred.reject(
              'getUserProfile failed: username param is required.');
            $log.debug('getUserProfile failed: username param is required.');
          } else {

            var criteria = {};
            if (username) {
              criteria.username = username;
            }
            $log.debug('getUserProfile called', criteria);

            coreAPILoader().then(function (coreApi) {
              coreApi.user.get(criteria).execute(function (resp) {
                if (resp.error || !resp.result) {
                  deferred.reject(resp);
                } else {
                  $log.debug('getUser resp', resp);
                  //get user profile
                  deferred.resolve(resp.item);
                }
              });
            }, deferred.reject);
          }
          return deferred.promise;
        };
      }
    ])

    .service('userService', ['$q', 'coreAPILoader', '$log',
      'getUserProfile', 'pick', 'USER_WRITABLE_FIELDS', 'BASIC_USER_WRITABLE_FIELDS', 'trackerFactory', 'userState',
      function ($q, coreAPILoader, $log, getUserProfile, pick,
        USER_WRITABLE_FIELDS, BASIC_USER_WRITABLE_FIELDS, trackerFactory, userState) {

        this.updateUser = function (username, profile) {
          var deferred = $q.defer();
          profile = pick(profile, userState.isUserAdmin() ? USER_WRITABLE_FIELDS : BASIC_USER_WRITABLE_FIELDS);
          $log.debug('updateUser called', username, profile);
          coreAPILoader().then(function (coreApi) {
            var request = coreApi.user.patch({
              username: username,
              data: profile
            });
            request.execute(function (resp) {
              $log.debug('updateUser resp', resp);
              if (resp.error) {
                deferred.reject(resp);
              } else if (resp.result) {
                getUserProfile(username, true).then(function () {
                  trackerFactory.userEvent('User Updated', userState.getUsername(), userState.checkUsername(
                    username), {
                    updatedUserId: username,
                    updatedUserCompanyRole: profile.companyRole ? profile.companyRole : ''
                  });
                  deferred.resolve(resp);
                });
              } else {
                deferred.reject('updateUser');
              }
            });
          }, deferred.reject);
          return deferred.promise;
        };

        this.addUser = function(companyId, username, profile) {
          var deferred = $q.defer();
          coreAPILoader().then(function (coreApi) {
            profile = pick(profile, USER_WRITABLE_FIELDS);
            var request = coreApi.user.add({
              username: username,
              companyId: companyId,
              data: profile
            });
            request.execute(function (resp) {
              $log.debug('addUser resp', resp);
              if (resp.result) {
                getUserProfile(username, true).then(function () {
                  deferred.resolve(resp);
                });
              } else {
                deferred.reject(resp);
              }
            });
          });
          return deferred.promise;
        };

        this.deleteUser = function (username) {
          var deferred = $q.defer();
          coreAPILoader().then(function (coreApi) {
            var request = coreApi.user.delete({
              username: username
            });
            request.execute(function (resp) {
              $log.debug('deleteUser resp', resp);
              if (resp.result) {
                deferred.resolve(resp);
              } else {
                deferred.reject(resp);
              }
            });
          });
          return deferred.promise;
        };

        this.getUsers = function (search, cursor) {

          var query = search.filter ? search.filter : '';
          if (search.query) {
            query += query ? ' AND (' + search.query + ')' : search.query;
          }

          var obj = {
            'companyId': search.companyId,
            'search': query,
            'cursor': cursor,
            'count': search.count,
            'sort': search.sortBy + (search.reverse ? ' desc' : ' asc')
          };

          $log.debug('getUsers', obj);
          var deferred = $q.defer();
          coreAPILoader().then(function (coreApi) {
            var request = coreApi.user.list(obj);
            request.execute(function (resp) {
              $log.debug('getUsers resp', resp);
              if (resp.result) {
                deferred.resolve(resp.result);
              } else {
                deferred.reject('getUsers');
              }
            });
          }, deferred.reject);
          return deferred.promise;
        };

        this.exportCompanyUsers = function (companyId) {
          var deferred = $q.defer();
          coreAPILoader().then(function (coreApi) {
            var request = coreApi.user.export({
              companyId: companyId
            });
            request.execute(function (resp) {
              $log.debug('exportUsers resp', resp);
              if (resp.result) {
                deferred.resolve(resp);
              } else {
                deferred.reject(resp);
              }
            });
          });
          return deferred.promise;
        };

      }
    ]);

})(angular);
