/* jshint unused: false */
(function (angular) {

  'use strict';

  angular.module('risevision.common.components.scrolling-list', [
      'rvScrollEvent'
    ]);

})(angular);
