'use strict';

angular.module('risevision.schedules.controllers')
  .controller('playlistItemModal', ['$scope', '$modalInstance', '$loading',
    'playlistFactory', 'playlistItem', 'userState', 'presentationFactory', 'TRANSITION_TYPES',
    function ($scope, $modalInstance, $loading, playlistFactory, playlistItem,
      userState, presentationFactory, TRANSITION_TYPES) {
      $scope.transitionTypes = TRANSITION_TYPES;
      $scope.companyId = userState.getSelectedCompanyId();
      $scope.playlistItem = angular.copy(playlistItem);
      $scope.isNew = playlistFactory.isNew(playlistItem);

      configurePlayUntilDone();

      $scope.$watch('loadingTemplate', function (loading) {
        if (loading) {
          $loading.start('playlist-item-modal-loader');
        } else {
          $loading.stop('playlist-item-modal-loader');
        }
      });

      $scope.selectPresentation = function () {
        presentationFactory.openPresentationSelector()
          .then(function (presentationDetails) {
            $scope.playlistItem.objectReference = presentationDetails[0];
            $scope.playlistItem.presentationType = presentationDetails[2];
            configurePlayUntilDone();
          });
      };

      function configurePlayUntilDone() {
        $scope.playUntilDoneSupported = true;
        $scope.loadingTemplate = true;

        presentationFactory.getPresentationCached($scope.playlistItem.objectReference)
          .then(function (presentation) {
            return playlistFactory.initPlayUntilDone($scope.playlistItem, presentation, $scope.isNew);
          })
          .then(function (playUntilDone) {
            $scope.playUntilDoneSupported = playUntilDone;
          })
          .finally(function () {
            $scope.loadingTemplate = false;
          });
      }

      $scope.saveDisabled = function() {
        return ($scope.playlistItemFields.itemDuration && $scope.playlistItemFields.itemDuration.$invalid) || 
          ($scope.playlistItemFields.urlField && $scope.playlistItemFields.urlField.$invalid && 
          ($scope.playlistItemFields.urlField.$dirty || $scope.isNew));
      };

      $scope.save = function () {
        if ($scope.saveDisabled()) {
          console.error('form not valid');

          return;
        }

        angular.copy($scope.playlistItem, playlistItem);

        playlistFactory.updatePlaylistItem(playlistItem);

        $scope.dismiss();
      };

      $scope.dismiss = function () {
        $modalInstance.dismiss();
      };
    }
  ]);
