'use strict';

angular.module('risevision.common.header.directives')
  .directive('requireRole', ['userState',
    function (userState) {
      return {
        restrict: 'A',
        priority: 100000,
        scope: false,

        compile: function (element, attr) {
          if (!userState.hasPermission(attr.requireRole)) {
            angular.forEach(element.children(), function (elm) {
              try {
                elm.remove();
              } catch (ignore) {}
            });
            element.remove();
          }
        }
      };
    }
  ]);