'use strict';

angular.module('risevision.editor.services', [
  'risevision.common.config',
  'risevision.common.header',
  'risevision.common.gapi',
  'risevision.store.product',
  'risevision.displays.services'
]);
angular.module('risevision.editor.filters', []);
angular.module('risevision.editor.directives', [
  'risevision.editor.filters'
]);