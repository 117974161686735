(function () {
  'use strict';

  /*jshint camelcase: false */

  angular.module('risevision.displays.services')
    .constant('DISPLAY_WRITABLE_FIELDS', [
      'name', 'status', 'useCompanyAddress', 'addressDescription', 'street',
      'unit', 'city', 'province', 'country', 'postalCode', 'timeZoneOffset',
      'restartEnabled', 'restartTime', 'autostartOnBoot', 'width',
      'height', 'orientation', 'monitoringEnabled', 'monitoringEmails', 'monitoringSchedule',
      'activationKey', 'overrideTakeoverAllowed'
    ])
    .service('display', ['$rootScope', '$q', '$log', 'coreAPILoader',
      'userState', 'screenshotRequester', 'pick', 'displayActivationTracker',
      'DISPLAY_WRITABLE_FIELDS',
      function ($rootScope, $q, $log, coreAPILoader, userState,
        screenshotRequester, pick, displayActivationTracker,
        DISPLAY_WRITABLE_FIELDS) {

        var service = {
          get: function (displayId) {
            var deferred = $q.defer();

            var obj = {
              'id': displayId
            };

            $log.debug('get display called with', displayId);
            coreAPILoader().then(function (coreApi) {
                return coreApi.display.get(obj);
              })
              .then(function (resp) {
                $log.debug('get display resp', resp);

                var item = resp.result.item;
                if (item) {
                  item.lastActivityDate = item.onlineStatus === 'online' ? new Date() : (item
                    .lastActivityDate ?
                    new Date(item.lastActivityDate) : '');

                  if (item.overrideTakeoverAllowed === undefined) {
                    item.overrideTakeoverAllowed = true;
                  }

                  item.autostartOnBoot = item.autostartOnBoot === undefined ? true : item.autostartOnBoot;

                  $rootScope.$broadcast('displaysLoaded', [item]);
                  displayActivationTracker.track([item]);
                }

                deferred.resolve(resp.result);
              })
              .then(null, function (e) {
                console.error('Failed to get display.', e);
                deferred.reject(e);
              });

            return deferred.promise;
          },
          add: function (display) {
            var deferred = $q.defer();

            var fields = pick.apply(this, [display].concat(DISPLAY_WRITABLE_FIELDS));
            fields.assignLicense = display.playerProAuthorized;
            var obj = {
              'companyId': userState.getSelectedCompanyId(),
              'data': fields
            };
            coreAPILoader().then(function (coreApi) {
                return coreApi.display.add(obj);
              })
              .then(function (resp) {
                $log.debug('added display', resp);
                deferred.resolve(resp.result);
              })
              .then(null, function (e) {
                console.error('Failed to add display.', e);
                deferred.reject(e);
              });
            return deferred.promise;
          },
          update: function (displayId, display) {
            var deferred = $q.defer();

            var fields = pick.apply(this, [display].concat(
              DISPLAY_WRITABLE_FIELDS));
            var obj = {
              'id': displayId,
              'data': fields
            };

            $log.debug('update display called with', displayId);
            coreAPILoader().then(function (coreApi) {
                return coreApi.display.patch(obj);
              })
              .then(function (resp) {
                $log.debug('update display resp', resp);
                deferred.resolve(resp.result);
              })
              .then(null, function (e) {
                console.error('Failed to update display.', e);
                deferred.reject(e);
              });

            return deferred.promise;
          },
          delete: function (displayId) {
            var deferred = $q.defer();

            var obj = {
              'id': displayId
            };

            $log.debug('delete display called with', displayId);
            coreAPILoader().then(function (coreApi) {
                return coreApi.display.delete(obj);
              })
              .then(function (resp) {
                $log.debug('delete display resp', resp);
                deferred.resolve(resp);
              })
              .then(null, function (e) {
                console.error('Failed to delete display.', e);
                deferred.reject(e);
              });

            return deferred.promise;
          },
          restart: function (displayId) {
            var deferred = $q.defer();

            var obj = {
              'id': displayId
            };

            $log.debug('restart display called with', displayId);
            coreAPILoader().then(function (coreApi) {
                return coreApi.display.restart(obj);
              })
              .then(function (resp) {
                $log.debug('restart display resp', resp);
                deferred.resolve(resp);
              })
              .then(null, function (e) {
                console.error('Failed to restart display.', e);
                deferred.reject(e);
              });

            return deferred.promise;
          },
          reboot: function (displayId) {
            var deferred = $q.defer();

            var obj = {
              'id': displayId
            };

            $log.debug('reboot display called with', displayId);
            coreAPILoader().then(function (coreApi) {
                return coreApi.display.reboot(obj);
              })
              .then(function (resp) {
                $log.debug('reboot display resp', resp);
                deferred.resolve(resp);
              })
              .then(null, function (e) {
                console.error('Failed to reboot display.', e);
                deferred.reject(e);
              });

            return deferred.promise;
          },
          requestScreenshot: function (displayId) {
            $log.debug('request screenshot called with', displayId);

            return screenshotRequester.request(function (clientId) {
                return coreAPILoader().then(function (coreApi) {
                  return coreApi.display.requestScreenshot({
                    id: displayId,
                    clientId: clientId
                  });
                });
              })
              .then(function (resp) {
                $log.debug('request screenshot resp', resp);
                return resp;
              })
              .then(null, function (e) {
                console.error('Failed screenshot request', e);
                return $q.reject(e);
              });
          },
          uploadControlFile: function (displayId, controlFileContents) {
            var deferred = $q.defer();

            $log.debug('uploadControlFile called with', displayId, controlFileContents);
            coreAPILoader().then(function (coreApi) {
                return coreApi.display.uploadControlFile({
                  'id': displayId,
                  'controlFileContents': controlFileContents
                });
              })
              .then(function (resp) {
                $log.debug('uploadControlFile resp', resp);
                deferred.resolve(resp);
              })
              .then(null, function (e) {
                console.error('Failed to upload control file.', e);
                deferred.reject(e);
              });

            return deferred.promise;
          },
          hasFreeDisplays: function (companyId, displayIds) {
            var deferred = $q.defer();

            $log.debug('hasFreeDisplays called with', companyId, displayIds);
            coreAPILoader().then(function (coreApi) {
                return coreApi.display.hasFreeDisplays({
                  'companyId': companyId,
                  'data': {
                    'displayIds': displayIds
                  }
                });
              })
              .then(function (resp) {
                $log.debug('hasFreeDisplays resp', resp);
                deferred.resolve(resp.result && resp.result.items);
              })
              .then(null, function (e) {
                console.error('Failed to retrieve hasFreeDisplays.', e);
                deferred.reject(e);
              });

            return deferred.promise;
          },
          summary: function (companyId) {
            companyId = companyId || userState.getSelectedCompanyId();
            var deferred = $q.defer();

            $log.debug('summary called with', companyId);
            coreAPILoader().then(function (coreApi) {
                return coreApi.display.summary({
                  'companyId': companyId,
                  'includeSubcompanies': false
                });
              })
              .then(function (resp) {
                $log.debug('summary resp', resp);
                deferred.resolve(resp.result);
              })
              .then(null, function (e) {
                console.error('Failed to retrieve summary.', e);
                deferred.reject(e);
              });

            return deferred.promise;
          },
          export: function () {
            var companyId = userState.getSelectedCompanyId();
            var deferred = $q.defer();

            $log.debug('export called with', companyId);
            coreAPILoader().then(function (coreApi) {
                return coreApi.display.export({
                  'companyId': companyId
                });
              })
              .then(function (resp) {
                $log.debug('export resp', resp);
                deferred.resolve(resp.result);
              })
              .catch(function (e) {
                console.error('Failed to export displays.', e);
                deferred.reject(e);
              });

            return deferred.promise;
          },
          move: function (displayId, companyId) {
            var deferred = $q.defer();

            $log.debug('move called with', companyId);
            coreAPILoader().then(function (coreApi) {
                return coreApi.display.move({
                  'id': displayId,
                  'companyId': companyId
                });
              })
              .then(function (resp) {
                $log.debug('move resp', resp);
                deferred.resolve(resp.result);
              })
              .catch(function (e) {
                console.error('Failed to move displays.', e);
                deferred.reject(e);
              });

            return deferred.promise;
          }
        };

        return service;
      }
    ]);
})();
