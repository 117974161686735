'use strict';

angular.module('risevision.common.header')
  .controller('AuthButtonsCtr', ['$scope',
    'userState', 'userAuthFactory', '$loading', 'bindToScopeWithWatch', 'environment',
    function ($scope, userState, userAuthFactory,
      $loading, bindToScopeWithWatch, environment) {

      $scope.rvaUrl = environment.RVA_URL;

      $scope.spinnerOptions = {
        color: '#999',
        hwaccel: true,
        radius: 10
      };

      //watch on username change and populate onto scope variables requried
      // for rendering UI

      $scope.$watch(function () {
          return userState.getUserPicture();
        },
        function () {
          $scope.userPicture = userState.getUserPicture();
        });
      $scope.$watch(function () {
          return userState.getUserCompanyName();
        },
        function () {
          $scope.companyName = userState.getUserCompanyName();
        });

      $scope.$watch(function () {
          return userState.getUsername();
        },
        function () {
          $scope.username = userState.getUsername();
        });
      bindToScopeWithWatch(userState.isRiseAdmin, 'isRiseAdmin', $scope);
      bindToScopeWithWatch(userState.isAssignedPublisher, 'isAssignedPublisher', $scope);

      $loading.startGlobal('auth-buttons-silent');
      userAuthFactory.authenticate(false).finally(function () {
        $loading.stopGlobal('auth-buttons-silent');
      });

    }
  ]);
