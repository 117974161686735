'use strict';

angular.module('risevision.schedules.directives')
  .directive('scheduleFields', ['$modal', 'presentationFactory', 'scheduleFactory', 'playlistFactory', 'playerLicenseFactory', '$sce',
    'environment', 'ngModalService', 'presentationUtils', 'trackerFactory', 'userState', 'bindToScopeWithWatch', 'scheduleActions',
    function ($modal, presentationFactory, scheduleFactory, playlistFactory, playerLicenseFactory, $sce, environment, ngModalService,
        presentationUtils, trackerFactory, userState, bindToScopeWithWatch, scheduleActions) {
      return {
        restrict: 'E',
        templateUrl: 'partials/schedules/schedule-fields.html',
        link: function ($scope) {
          var originalChangeDate = scheduleFactory.schedule.changeDate;
          $scope.applyTimeline = false;
          $scope.freeDisplays = [];
          $scope.licenseRequired = false;
          $scope.factory = scheduleFactory;
          $scope.playerLicenseFactory = playerLicenseFactory;
          $scope.isMobile = presentationUtils.isMobileBrowser();
          bindToScopeWithWatch(userState.isAssignedPublisher, 'isAssignedPublisher', $scope);

          $scope.$watchGroup(['factory.schedule.distribution', 'factory.schedule.distributeToAll', function () {
            return scheduleFactory.requiresLicense();
          }], function () {
            playerLicenseFactory.apiError = '';

            scheduleFactory.hasFreeDisplays()
              .then(function (result) {
                $scope.freeDisplays = result;
                $scope.licenseRequired = scheduleFactory.requiresLicense();
              });
          });

          $scope.$watch('factory.schedule.distributeToSubcompanies', function (value, oldValue) {
            if (value !== oldValue) {
              trackerFactory.scheduleEvent('Distribute To Subcompanies Changed', scheduleFactory.schedule.scheduleId, scheduleFactory.schedule.scheduleName, {
                distributeToSubcompanies: value
              });
            }
          });

          $scope.$watch('factory.schedule.scheduleType', function (value, oldValue) {
            if (value !== oldValue) {
              trackerFactory.scheduleEvent('Schedule Type Changed', scheduleFactory.schedule.scheduleId, scheduleFactory.schedule.scheduleName, {
                scheduleType: value
              });
            }
          });

          $scope.licenseFreeDisplays = function () {
            playerLicenseFactory.confirmAndLicense($scope.freeDisplays).then(function () {
              $scope.freeDisplays = [];
            });
          };

          var openPlaylistModal = function (playlistItem) {
            $modal.open({
              templateUrl: 'partials/schedules/playlist-item.html',
              controller: 'playlistItemModal',
              size: 'md',
              resolve: {
                playlistItem: function () {
                  return playlistItem;
                }
              }
            });
          };

          $scope.addUrlItem = function () {
            openPlaylistModal(playlistFactory.getNewUrlItem());
          };

          $scope.addPresentationItem = function () {
            presentationFactory.openPresentationMultiSelector()
              .then(function (presentations) {
                if (presentations && presentations.length === 1) {
                  openPlaylistModal(playlistFactory.newPresentationItem(presentations[0]));
                } else {
                  playlistFactory.addPresentationItems(presentations);
                }
              });
          };

          $scope.confirmDelete = function () {
            ngModalService.confirm('Delete Schedule?',
                'Are you sure you want to delete this Schedule?',
                'Yes', 'No')
              .then(scheduleFactory.deleteSchedule);
          };

          $scope.getEmbedUrl = function () {
            if (!scheduleFactory.schedule) {
              return null;
            }
            var url = environment.SHARED_SCHEDULE_URL.replace('SCHEDULE_ID', scheduleFactory.schedule.id) +
              '&env=apps_schedule';

            if (!$scope.applyTimeline) {
              url += '&applyTimeline=false';
            }

            if (originalChangeDate !== scheduleFactory.schedule.changeDate) {
              url += '&dataSource=core&changeDate=' + scheduleFactory.schedule.changeDate;
            }

            return $sce.trustAsResourceUrl(url);
          };

          $scope.assignUsersVisible = function () {
            return userState.hasRole('cp') && (userState.isK12Customer() || userState.isHigherEdCustomer());
          };

          $scope.assignUsers = function () {
            scheduleActions.assignUsersToSchedule(
              scheduleFactory.schedule && scheduleFactory.schedule.assignedUsers ? angular.copy(scheduleFactory.schedule.assignedUsers) : []
            ).then(function (users) {
              if (!angular.equals(scheduleFactory.schedule.assignedUsers, users)) {
                scheduleFactory.schedule.assignedUsers = users;
                trackerFactory.scheduleEvent('Schedule Assigned Users Changed', scheduleFactory.schedule.scheduleId, scheduleFactory.schedule.scheduleName, {
                  assignedUsers: users.length
                });
              }
            }).catch(function () {});
          };

        } //link()
      };
    }
  ]);
