/*jshint multistr: true */
'use strict';

angular.module('risevision.schedules.controllers')
  .controller('SharedSchedulePopoverController', ['$scope', '$window', 'scheduleFactory', 'trackerFactory',
    'environment', '$state',
    function ($scope, $window, scheduleFactory, trackerFactory, environment, $state) {
      $scope.scheduleFactory = scheduleFactory;
      $scope.isScheduleDetails = $state.current.name === 'apps.schedules.details';
      $scope.currentTab = 'link';

      $scope.getLink = function () {
        return $scope.schedule ? environment.SHARED_SCHEDULE_URL.replace('SCHEDULE_ID', $scope.schedule.id) : '';
      };

      $scope.copyToClipboard = function (text) {
        $scope.trackScheduleShared();

        if ($window.navigator.clipboard) {
          $window.navigator.clipboard.writeText(text);
        }
      };

      $scope.onTextFocus = function (event) {
        event.target.select();
      };

      $scope.trackScheduleShared = function (extraProperties) {
        var properties = extraProperties || {};
        properties.source = properties.source || $scope.currentTab;

        trackerFactory.scheduleEvent('schedule shared', $scope.schedule.id, $scope.schedule.name, properties);
      };
    }
  ]);
